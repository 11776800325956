<template lang="pug">
div.v-orators
  div.v-orators__header
    div.v-orators__header_info Зал ожидания

  div.v-orators__list
    slot
    div.v-orators__item(
      v-for='orator in orators'
      v-show='orator.visible'
      :key='orator.name'
      :class='{ "show-background": !orator.isVideoOn }'
      ref='orator'
      :data-name='orator.name'
    )
      div.v-orators__item-panel
        div.v-orators__item-info
          div.v-orators__item-name {{ orator.name.replace(/-[^-]*$/, '') }}
        div.v-orators__item-actions
          v-icon.icon.v-orators__item-indicator_audio(
            :icon='getOratorMicIcon(orator)'
            size='24'
            :style='{ color: getAudioStatusColor(orator) }'
          )
</template>

<script>
export default {
  name: 'VWaitingRoom',
  props: {
    orators: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getAudioStatusColor(orator) {
      return orator.isMicOn ? '#219653' : '#EB5757'
    },
    getOratorMicIcon(orator) {
      return orator.isMicOn ? 'MicIcon' : 'MicOffIcon'
    },
  },
}
</script>

<style lang="scss">
.v-orators {
  height: 100%;
  overflow-x: hidden;
  min-width: 220px;
  width: 220px;
  padding: 10px;
  border-radius: $border-radius;
  background-color: white;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
  text-align: left;
  color: #5f5f5f;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }

  &__item {
    position: relative;
    height: 110px;
    background: #000000;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    border: 1px solid #b9b9c3;
    border-radius: $border-radius;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      .v-orators__item-dropdown {
        visibility: visible;
      }
    }

    &-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 0;
      bottom: 0;
      // margin: 0 3px;
      width: 100%;
      padding: 0px 4px;
      // width: calc(100% - 10px);
      // height: 20px;
    }

    &-indicator {
      &_video {
        width: 10px;
        height: 10px;
        margin: 2px;
        border-radius: 50%;
      }
    }

    &-name {
      margin: 2px;
      color: white;
      max-width: 140px;
    }

    &.show-background {
      background: url('~@/images/account_circle.png') center center no-repeat;
      background-size: cover;

      video {
        visibility: hidden;
      }
    }

    &-info {
      display: flex;
      align-items: center;
    }

    & video {
      height: 105%;
      object-fit: contain;
    }
  }

  @media (max-width: 800px) {
    background: none;
    box-shadow: none;

    width: 100%;
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item,
    .orator-display {
      margin: 0 0 12px;
      width: 48%;
      height: 100px;
      background: #000000;
    }
  }
}
</style>
